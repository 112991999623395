import { defineStore } from "pinia";
import { useNuxtApp, showError } from "nuxt/app";
import { $useFetch } from "~/composables/useFetch";
import { useUser } from "~/composables/useUser";

export const useRegionsStore = defineStore("regions", {
	state: () => ({
		regions: [],
	}),
	actions: {
		async fetchSectionTree() {
			const { api, userRoles } = useNuxtApp();
			let apiUrl = api.regions;

			if (userRoles.isAgent()) {
				apiUrl += "?agent=" + useUser().userId;
			}

			const { error } = await $useFetch(apiUrl, {
				method: "GET",
				onResponse({ response }) {
					if (response?.status === 200) useRegionsStore().regions = response._data.data;
				},
			});

			if (error.value) {
				throw showError({ statusCode: 500, statusMessage: error.value.message });
			}
		},

		getRegionById(id) {
			return useRegionsStore().regions.find((item) => item.id === id) || "";
		},
		getRegionNameById(id) {
			return this.getRegionById(id).name;
		},
	},
	getters: {
		getRegions() {
			return this.regions;
		},
		getRegionNamed() {
			const regionsByName = [];
			for (const item of this.regions) {
				regionsByName.push(item.name);
			}
			return regionsByName;
		},
		getRegionIdByName: (state) => (name) => state.regions.find((el) => el.name === name),
	},
});
